// main.js 或 entry-server.js
 
import { createApp } from 'vue'
import ArcoVue from '@arco-design/web-vue';
// 额外引入图标库
import ArcoVueIcon from '@arco-design/web-vue/es/icon';

import App from './App.vue'
import router from './router'
import '@arco-design/web-vue/dist/arco.css';

//全局引入echarts
import 'echarts'
import ECharts from 'vue-echarts'

const app = createApp(App)

//注册组件ECharts 到app
app.component('VueEcharts', ECharts)

app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(router);
app.mount('#app');
<template>
	
	<router-view/>
	
</template>


<script setup>
	import { onMounted,ref } from 'vue';
	import UAParser from 'ua-parser-js';
	import { useRouter } from "vue-router"
	
	const router = useRouter();
	
	let devices = ref(false);
	onMounted(() => {
		const parser = new UAParser();
		const result = parser.getResult();
		// console.log(result)
		if (result.device.type === 'mobile') {
			//跳转路由
			router.push({
				path: '/incompatible'
			});
		}
	});

</script>

<style lang="scss">
*{
	padding: 0;
	margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
a{text-decoration:none; color: #333;}
.imgShow{
	width:200px; /* 设置容器宽度 */
	position: relative;
	.img{
		width: 100%; /* 图片宽度填满容器 */
		height: 100%; /* 图片高度填满容器 */
	}
	.close{
		cursor:pointer;
		position: absolute;
		top: -10px;
		right: -10px;
		width: 30px;
		height: 30px;
		background: #555;
		border-radius: 50%;
		color: #fff;
		box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
	}
}
</style>

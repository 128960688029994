import { createRouter, createWebHistory } from 'vue-router'



const routes = [
  
	{
		path: '/',
		name:"Views",
		component:() => import('@/views/index.vue'),
		children: [
			
			{
				path: '',
				name:"store",
				component:() => import('@/views/store/index.vue'),
				children: [
					{
						path:'',
						name:'storeHome',
						component: () => import('@/views/store/page/Home/index.vue'),
						meta: { title: '数据概况',requireAuth: true },
					},
					
					{
						path:'/store/user',
						name:'storeUser',
						component: () => import('@/views/store/page/User/index.vue'),
						children: [
							{
								path:'',
								name:'userList',
								component: () => import('@/views/store/page/User/List/index.vue'),
								meta: { title: '用户管理',requireAuth: true },
							},
							{
								path:'/store/user/add',
								name:'userAdd',
								component: () => import('@/views/store/page/User/Add/index.vue'),
								meta: { title: '添加用户',requireAuth: true },
							},
						]
					},
					{
						path:'/store/recharge',
						name:'storeRecharge',
						component: () => import('@/views/store/page/Recharge/index.vue'),
						children: [
							{
								path:'',
								name:'rechargeList',
								component: () => import('@/views/store/page/Recharge/List/index.vue'),
								meta: { title: '充值订单',requireAuth: true },
							},
							{
								path:'/store/recharge/add',
								name:'rechargeAdd',
								component: () => import('@/views/store/page/Recharge/Add/index.vue'),
								meta: { title: '充值活动',requireAuth: true },
							},
						]
					},
				
					{
						path:'/store/app',
						name:'storeApp',
						component: () => import('@/views/store/page/App/index.vue'),
						children: [
							{
								path:'',
								name:'AppList',
								component: () => import('@/views/store/page/App/List/index.vue'),
								meta: { title: '电商营运',requireAuth: true },
							}
						]
					},
					
					{
						path:'/store/supplier',
						name:'storeSupplier',
						component: () => import('@/views/store/page/Supplier/index.vue'),
						children: [
							{
								path:'',
								name:'supplierStatistics',
								component: () => import('@/views/store/page/Supplier/statistics/index.vue'),
								meta: { title: '供应商统计',requireAuth: true },
							},
							{
								path:'/store/supplier/list',
								name:'supplierList',
								component: () => import('@/views/store/page/Supplier/List/index.vue'),
								meta: { title: '供应商管理',requireAuth: true },
							},
							{
								path:'/store/supplier/payBuy',
								name:'payBuy',
								component: () => import('@/views/store/page/Supplier/Log/payBuy.vue'),
								meta: { title: '已结款票据',requireAuth: true },
							},
							{
								path:'/store/supplier/notPayBuy',
								name:'notPayBuy',
								component: () => import('@/views/store/page/Supplier/Log/notPayBuy.vue'),
								meta: { title: '未结款票据',requireAuth: true },
							},
							{
								path:'/store/supplier/displayGoods',
								name:'displayGoods',
								component: () => import('@/views/store/page/Supplier/Log/displayGoods.vue'),
								meta: { title: '陈列商品票据',requireAuth: true },
							},
							{
								path:'/store/supplier/displayCashback',
								name:'displayCashback',
								component: () => import('@/views/store/page/Supplier/Log/displayCashback.vue'),
								meta: { title: '陈列返现票据',requireAuth: true },
							},
							{
								path:'/store/supplier/refund',
								name:'refund',
								component: () => import('@/views/store/page/Supplier/Log/refund.vue'),
								meta: { title: '退款票据',requireAuth: true },
							},
							{
								path:'/store/supplier/notRefund',
								name:'notRefund',
								component: () => import('@/views/store/page/Supplier/Log/notRefund.vue'),
								meta: { title: '未退款票据',requireAuth: true },
							}
							
						]
					},
					
					
					{
						path:'/store/brand',
						name:'storeBrand',
						component: () => import('@/views/store/page/Brand/index.vue'),
						children: [
							
							{
								path:'',
								name:'brandList',
								component: () => import('@/views/store/page/Brand/List/index.vue'),
								meta: { title: '品牌管理',requireAuth: true },
							},
						]
					},
					{
						path:'/store/equipment',
						name:'storeEquipment',
						component: () => import('@/views/store/page/Equipment/index.vue'),
						children: [
							{
								path:'',
								name:'equipmentList',
								component: () => import('@/views/store/page/Equipment/List/index.vue'),
								meta: { title: '设备管理',requireAuth: true },
							}
						]
					},
					{
						path:'/store/cashier',
						name:'storeCashier',
						component: () => import('@/views/store/page/Cashier/index.vue'),
						children: [
							{
								path:'',
								name:'cashierList',
								component: () => import('@/views/store/page/Cashier/List/index.vue'),
								meta: { title: '收银员',requireAuth: true },
							}
						]
					},
					{
						path:'/store/goods',
						name:'storeGoods',
						component: () => import('@/views/store/page/Goods/index.vue'),
						children: [
							
							{
								path:'',
								name:'goodsStatistics',
								component: () => import('@/views/store/page/Goods/statistics/index.vue'),
								meta: { title: '数据报表',requireAuth: true },
							},
							{
								path:'/store/goods/list',
								name:'goodsList',
								component: () => import('@/views/store/page/Goods/List/index.vue'),
								meta: { title: '库存商品管理',requireAuth: true },
							},
							{
								path:'/store/goods/log/enter',
								name:'logEnter',
								component: () => import('@/views/store/page/Goods/Log/enter.vue'),
								meta: { title: '入库日志',requireAuth: true },
							},
							{
								path:'/store/goods/log/damage',
								name:'logDamage',
								component: () => import('@/views/store/page/Goods/Log/damage.vue'),
								meta: { title: '货损日志',requireAuth: true },
							},
							{
								path:'/store/goods/log/supplierRefund',
								name:'supplierRefund',
								component: () => import('@/views/store/page/Goods/Log/supplierRefund.vue'),
								meta: { title: '供应商退货',requireAuth: true },
							}
						]
					},
					
					{
						path:'/store/refund',
						name:'Refund',
						component: () => import('@/views/store/page/Refund/index.vue'),
						meta: { title: '供应商退货',requireAuth: true },
					},
					{
						path:'/store/sale',
						name:'Sale',
						component: () => import('@/views/store/page/Sale/index.vue'),
						children: [
							{
								path:'',
								name:'ReportForms',
								component: () => import('@/views/store/page/Sale/ReportForms/index.vue'),
								meta: { title: '销售报表',requireAuth: true },
							},
							{
								path:'/store/sale/List',
								name:'storeSaleList',
								component: () => import('@/views/store/page/Sale/List/index.vue'),
								meta: { title: '库销订单',requireAuth: true },
							}
						]
						
					},						
					
					
					
				]
			},
			{
				path: '/logon',
				name:"Logon",
				component:() => import('@/views/logon/index.vue'),
				meta: { title: '欢迎登录',requireAuth: false },
			},
		]
	},
	//404页面
	{
		path: '/:pathMatch(.*)',
		name: 'NotFound',
		component:() => import('@/views/404.vue'),
		meta: { title: '404'},
	},
	//提示不兼容页面
	{
		path: '/incompatible',
		name: 'Incompatible',
		component:() => import('@/views/incompatible.vue'),
		meta: { title: '不支持该设备'},
	}
	
]

// nginx配置
// location / {
//       try_files $uri $uri/ /index.html;
//     }

const router = createRouter({
  history: createWebHistory(),
  //mode:'hash',
  routes,
 
})

//登录验证拦截
router.beforeEach((to,from,next)=>{
	if(to.meta.requireAuth){  // 判断该路由是否需要登录权限
       //获取当前登录的用户的信息
	   const USER = localStorage.getItem('USER');
	   
		if(USER=="" || USER==null) {  // 从本地存储localStorage获取当前的token是否存在
          router.push({
          	path:'/logon'
          });
        }else{
            next() //如果token不存在，就跳到首页
        }
	}else{
       next()
    }
});


router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = '稻米云仓 - '+to.meta.title;
    }
	  
    next();
});

export default router
